import React from "react";
export function Contact() {
  return (
    <div>
      <h1>Please Contact</h1>
      <p>SLK Plus Import-Import Co., LTD</p>
      <p>Ban Phanhman, Sisattanak District</p>
      <p>Vientiane, Lao P.D.R</p>
      <p>Tel : 856-20-22224455</p>
      <p>Tel : 856-20-28292489</p>
      <p>E-mail : anouphone.bouthavong@gmail.com</p>
      <p>Contact: ANOUPHONE BOUTHAVONG</p>
    </div>
  );
}