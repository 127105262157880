import React from "react";
export function Reference() {
  return (
    <div>
      <h1> SLK HIS Site Reference</h1>
      <p>ໂຮງໜໍແຂວງຫຼວງພະບາງ</p>
      <p>ໂຮງໜໍຊຸມຊົນເມືອງຫົງສາ</p>
      <p>ຫ້ອງກວດພະຍາດເອກະຊົນ ດຣ ສົມບູນ ທໍາມະລັດ</p>
      <p>ຄລິນີກ Phonethida</p>
      <p>ຄລິນີກ Ohxy</p>
    </div>
  );
}