// eslint-disable-next-line
import './App.css';
import { FaHome, FaInfoCircle, FaBook, FaPhone, FaDiceD6 } from "react-icons/fa";

import React from 'react';
import { About } from './about'
import { Contact } from './contact';
import { Reference } from './reference';

import Carousel from 'react-bootstrap/Carousel';
import p1 from "./pic1.jpg"
import p2 from "./pic2.jpg"
import p3 from "./pic3.jpg"
import logo from "./logo.png"

import { Navbar, Nav } from "react-bootstrap";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes,
  Outlet,
} from "react-router-dom";


function Home() {
  return (
    <Carousel>
    <Carousel.Item interval={5000}>
      <img src={p1} width={1920} height={600} className="d-block w-100 h-100"
        alt="One"
      />
    </Carousel.Item>

    <Carousel.Item interval={5000}>
      <img src={p2} width={1920} height={600} className="d-block w-100 h-100"
        alt="p2"
      />
    </Carousel.Item>

    <Carousel.Item interval={5000}>
      <img src={p3} width={1920} height={600} className="d-block w-100 h-100"
        alt="p3"
      />             
    </Carousel.Item>      
</Carousel>    
  );
}

function NotFound() {
  return (
      <div>
          <h2>404 Not Found</h2>
          <p>Sorry, the page you are looking for does not exist.</p>
      </div>
  );
}



function App() {
  return (
    
    <Router>
      <Navbar className="navbar-bg" expand="lg">
          <Navbar.Brand>
              <h1 className="navbar-brand-text">
                  <Link to="/" className="brand-link">
                    <img
                      src={logo}
                      className="logo img-fluid"
                      alt="Logo"
                      width="70" height="50"
                    />
                  </Link>
              </h1>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                  <Nav.Link as={Link} to="/" className="nav-link">
                      <FaHome /> Home
                  </Nav.Link>
                  <Nav.Link as={Link} to="/about" className="nav-link">
                      <FaInfoCircle /> About
                  </Nav.Link>
                  <Nav.Link as={Link} to="/contact" className="nav-link">
                      <FaPhone /> Contact
                  </Nav.Link>
                  <Nav.Link as={Link} to="/reference" className="nav-link">
                      <FaBook /> Reference
                  </Nav.Link>
                  <Nav.Link as={Link} to="https://app.slk-his.com" target="_blank" className="nav-link">
                      <FaDiceD6 /> App
                  </Nav.Link>                        
              </Nav>
          </Navbar.Collapse>
      </Navbar>
      <div className="container mt-4">
          <Routes>
              <Route path="/" element={<Outlet />}>
                  <Route index element={<Home />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/reference" element={<Reference />} />
                  <Route path="*" element={<NotFound />} />
              </Route>
          </Routes>
      </div>
    </Router>
  )
}

export default App
